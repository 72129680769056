<template>
    <div class="privacy-policy-wrapper">
        <div class="page-header page-header-small clear-filter" filter-color="primary">
            <div class="page-header-image"></div>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-10 ml-auto mr-auto">
                        <h1 class="title">PRACTITIONER</h1>
                        <h4>TERMS AND CONDITIONS OF SALE/SERVICE</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="section">
                <div class="container">
                    <div class="flex-row justify-content-center">
                        <div class="section-container">PREAMBLE
                            <br />
                            WHEREAS these T&Cs shall be read together with the Website Terms and Conditions, Privacy Policy and Cookie Policy.
                            <br />
                            The Practitioner that lists on the Platform thereby accepting these terms are also bound by the Website Terms and Conditions, Privacy Policy and Cookie Policy.
                        </div>
                        <ol>
                            <li class="section-container">DEFINITIONS AND INTERPRETATION
                                <ol>
                                    In this Agreement, unless clearly inconsistent with or otherwise indicated by the context:
                                    <li>any reference to the singular includes the plural and vice versa, any reference to natural persons includes legal persons (corporate or unincorporated) and vice versa and any reference to a gender includes the other gender;</li>
                                    <li>headings and the use of bold typeface are to be ignored;</li>
                                    <li>a reference to a recital, clause, sub-clause, paragraph, sub-paragraph, schedule or appendix is, unless indicated to the contrary, a reference to a recital, clause, sub-clause, paragraph, sub-paragraph, schedule or appendix of this Agreement;</li>
                                    <li>where any term is defined within the context of any particular clause or sub-clause, the term so defined shall, unless it appears clearly from such clause or sub-clause that such term has limited application to the relevant clause or sub-clause only, bear the meaning ascribed to it for all purposes in terms of this Agreement;</li>
                                    <li>in the interpretation of this Agreement, the contra proferentum rule of interpretation shall not apply (this Agreement being the product of negotiations between the Parties), nor shall this Agreement be construed in favour of or against any Party by reason of the extent to which any Party or its professional advisors participated in the preparation of this Agreement;</li>
                                    <li>the use of the words "include", "including" and "in particular" in this Agreement followed by a specific example or examples shall not be construed or interpreted as limiting the meaning of  the general wording preceding it and the eiusdem generis rule shall not be applied in the interpretation of such general wording and/or such specific example or examples and the words "other" or "otherwise" shall not be construed eiusdem generis with any preceding words where a wider construction is possible;</li>
                                    <li>“Agreement” means these T&C’s;</li>
                                    <li>“Practitioner” means a medical or wellness practitioner who are part of a registered medical or wellness practice (with an associated practice number) and who is also registered on the Platform offering his/her/ its services to the User of the Platform;</li>
                                    <li>“Platform” means  the Dr Remo Platform or “Platform” means the Online Platform located on the website hosted on the domain www.drremo.co.za;</li>
                                    <li>“User” means a Registered User who is a natural or a juristic person and utilises the Platform;</li>
                                </ol>
                            </li>
                            <li class="section-container">PAYMENT
                                <ol>
                                    <li>The payment for services rendered shall be both due and payable on a commissioned basis.</li>
                                    <li>The Company shall deduct an administrative fee, following the booking of a user, and thereafter, pay the remaining funds to the Supplier on a weekly basis.</li>
                                </ol>
                            </li>
                            <li class="section-container">DATA PROCESSING CONSENT
                                <ol>
                                    <li>The Supplier has been engaged or may possibly be engaged to supply goods or render services and therefore the Customer hereby consents thereto that the Supplier collect, hold, organise, store, use, administer and process my/our personal information for this purpose.</li>
                                    <li>
                                        The personal information (the “information”) in relation to this consent are, the:
                                        <ol>
                                            <li>The Customer’s contact - and invoicing details (including without limitation email addresses, phone numbers, physical, postal and website addresses VAT and Tax Numbers),</li>
                                            <li>Verification of the Customer’s details and may include credit check(s) on the Customer and its key decision-makers.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        The Customer acknowledges that it has the right to:
                                        <ol>
                                            <li>request a list of the names and addresses of any potential recipients of the information;</li>
                                            <li>to review and correct the Information; and</li>
                                            <li>to request the return or destruction of the Information once it is no longer required under the engagement with the Supplier or by law.</li>
                                        </ol>
                                    </li>
                                    <li>The Supplier undertakes that all personal information will be held securely to the standard directed by the Protection of Personal Information Act 4 of 2013 as amended (the “Act” or “POPIA”) and that the Company has bound its service providers (“Operators”) to a similar standard as required and prescribed by POPIA.</li>
                                </ol>
                            </li>
                            <li class="section-container">FAILURE TO PERFORM
                                <ol>
                                    <li>The Supplier will not be liable for any damages or losses incurred and is hereby indemnified by the Customer against any claim as a result any of the following, including without limitation:</li>
                                    <ol>
                                        <li>orders delayed or cancelled due to any legal consequence that occurs by the act of the law itself (or ipso jure), or</li>
                                        <li>if the Customer is in default of agreed payment terms, or</li>
                                        <li>as a result of an act of God (or force majeure) or incidents such as, without limitation: lockouts, strikes, epidemics, war, terrorist attacks, commandeering of property, fire, flood or any other similar events or anything not caused by the Supplier’s actions, the Supplier’s negligence or is outside the Supplier’s control but which results in a delay or cancellation; or</li>
                                        <li>in a total or partial shutdown of the Supplier or its suppliers.</li>
                                    </ol>
                                </ol>
                            </li>
                            <li class="section-container">NOVATION
                                <ol>
                                    <li>No Party may cede any or all of that Party's rights or delegate any or all of that Party's obligations under this Agreement without the prior written consent of the other Party.</li>
                                </ol>
                            </li>
                            <li class="section-container">REMOVAL OF A PRACTITIONER
                                <ol>
                                    <li>The Company reserves the right to remove any Practitioner from the Platform at its own discretion.</li>
                                </ol>
                            </li>
                            <li class="section-container">INDEMNITY
                                <ol>
                                    <li>Nothing in this Agreement shall be construed as precluding or limiting in any way the right of the Company to procure services or goods of any kind or nature whatsoever from any person or entity as the Company in its sole discretion deems appropriate.</li>
                                    <li>The Supplier hereby indemnifies and holds the Company harmless against any loss, claims, demands, proceedings, damages and expenses of whatsoever nature in respect of loss of or damage to any property of any person or loss of profits or inability to operate arising during the duration of this Agreement which the Company may suffer arising out the negligence or wilful conduct of the Supplier.</li>
                                </ol>
                            </li>
                            <li class="section-container">SEVERABILITY
                                <ol>
                                    <li>If any part of this Agreement is void, illegal, unenforceable, or in conflict with any law of the state or local Government over this Agreement, the validity of the remaining portions or provisions shall not be affected thereby.</li>
                                </ol>
                            </li>
                            <li class="section-container">NOTICES AND DOMICILIUM
                                <ol>
                                    <li>Each Party chooses as its domicilium citandi et executandi their respective addresses set out in the face of this agreement for all purposes arising out of or in connection with this agreement, at which addresses all the processes and notices arising out of or in connection with this agreement, its breach or termination, may validly be served upon or delivered to the Parties.</li>
                                </ol>
                            </li>
                            <li class="section-container">ENTIRE AGREEMENT
                                <ol>
                                    <li>This agreement constitutes the entire agreement between the Parties.</li>
                                    <li>No Party shall have any claim or right of action arising from any undertaking, representation or warranty not included in this agreement.</li>
                                    <li>No contract varying, adding to, deleting from or cancelling this agreement, and no waiver of any right under this agreement, will be effective unless reduced to writing and signed by or on behalf of the Parties.</li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "terms-and-conditions-practitioner",
    metaInfo() {
        return {
            title: "DrRemo | Terms And Conditions - Practitioner",
            meta: [
                {
                    name: "description",
                    content: "Read more about our terms of service for using the DrRemo platform as a practitioner."
                },
                {
                    name: "robots",
                    content: "index, follow"
                }
            ],
            link: [
                {
                    rel: "canonical",
                    href: "https://dr-remo.co.za/practitionerterms"
                }
            ]
        }
    }
}
</script>